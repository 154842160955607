.about-company{
    padding:50px 0;

}

.about-company span{
    font-family: 'syne';
    font-size:21px;
    font-weight: 600;
    color:#0049DC;
}

.about-company h3{
    font-size:33px;
    font-weight: 700;
    margin-bottom:30px;
}

.about-company p{
    color:#000;
}

.about-company img{
    padding:50px;
}

.about-company .about-com-img {
    position: relative;
    height:500px;
}
.about-company .about-com-img img{
    position: absolute;
    width:10%;
}

.about-company .about-com-img img:nth-child(1){
    width:280px!important;
    object-fit: contain;
    left:25%;
    top:65%;
    transform: translate(-50%,-50%);
    z-index:2;
}

.about-company .about-com-img img:nth-child(2){
    width:380px!important;
    object-fit: contain;
    left:50%;
    top:40%;
    transform: translate(-50%,-50%);
}
.about-company .about-com-img img:nth-child(3){
    width:230px!important;
    object-fit: contain;
    right:0%;
    top:40%;
    transform: translate(-10%,-50%);
}