.whyus{
    padding:100px 50px;
    background:#0033B4;
    color:#fff;
}
.whyus .text-align-center{
    text-align: center;
}

.whyus h1{
    font-size:72px;
    Font-weight:bold;
}
.whyus .feature-wrapper{
    padding-top:150px;
}

.whyus .features{
    padding:0px 80px; 
    height:300px;
    position: relative;
    cursor: pointer;
}
.whyus .features h1{
    position: absolute;
    top:-50%;
    left:0;
    opacity: 0.1;
    -webkit-text-stroke: 2px #fff;
    color:transparent;
    font-size: 270px;
    font-weight:300;
    transition: 0.7s ease;
}

.whyus .features h2{
    padding-right:100px;
    font-size:42px!important;
    transition: 1s;
}

.whyus .features:hover h1{
    color:#fff;
}

.whyus .features p{
    color:#fff;
    font-weight:300;
    font-size: 16px;
}

main {
    overflow-x: hidden;
    display: flex;
    position: sticky;
    top:0;
  }
  
  main h1 {
    margin: 0;
    padding: 0;
  }
  
  main section {
    min-width: 50vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4ch;
  }
  
  main section:nth-child(even) {
    background-color: teal;
    color: white;
  }
  

/* /responsive/ */

@media  (min-width: 1200px) and (max-width: 1440px){
    .whyus .features{
        padding:0px 60px; 
    }
    .whyus .features h2{
        padding-right:60px!important;
        font-size:40px!important;
        font-weight:600;
    }
}

@media only screen and (max-width: 480px){

    .whyus{
        padding:80px 20px; 
    }
    .whyus .text-align-center{
        text-align: center;
    }
    
    .whyus h1{
        font-size:42px;
        Font-weight:bold;
    }
    .whyus .feature-wrapper{
        padding-top:100px;
    }
    
    .whyus .features{
        padding:0px 20px; 
        height:350px;
    }
    .whyus .features h2{
        padding-right:75px;
    }

}

  