.whatsapp-icon{
    position: fixed;
    bottom:2%;
    left:2%;
    width:50px;
    height:50px;
    z-index:999;
    background-color: #00da20;
    border-radius: 100%;
    padding:10px;
}

.whatsapp-icon img{
    width:100%;
}

.above-footer{
    display: flex;
}

.above-footer .col50{
    width:50%;
}

#footer-company-info{
    color:#fff;
    padding:40px 20px 20px 20px;
}

.above-footer .join-info{
    background:#00102F;
    padding:100px 50px 80px 150px; 
    color:#fff;
}

.above-footer .join-info h2{
    font-size:62px;
    padding-bottom:20px;
}

.above-footer .join-info button{
    background:transparent;
    border-radius: 50%;
    border:1px solid #fff;
    width:70px;
    height:70px;
}

.above-footer .contact-info{
    background:#FFD600;
    padding:100px 150px 80px 50px; 
    color:#000;
}
.above-footer .contact-info p{
    color:#000;
}

.above-footer .contact-info h2{
    font-size:62px;
    padding-bottom:20px;
}

.above-footer .contact-info button{
    background:transparent;
    border-radius: 50%;
    border:1px solid #000;
    width:70px;
    height:70px;
}
.above-footer button{
    transition: 0.3s;
}
.above-footer button:hover{
    transform: rotate(45deg);
}
/* Footer  */
footer{
    padding:100px 40px 0;
    background:#0033B4;
    color:#fff;
    box-shadow:10px 10px 10px #00000056;
}

footer ul{
    list-style: none;
    
}

footer ul li{
    font-family: 'Syne',sans-serif!important;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
}
.quick-links a{ 
    color:#fff;
    text-decoration:none;
}

.quick-links a:hover{ 
    color:rgb(255, 255, 23);
    text-decoration:none;
}
footer .footer-info ul li{
    font-size:24px;
}

.scroll-top{
    position: relative;
    float:right;
    margin:10px;
    width:50px;
    height:50px;
    border:1px solid #fff;
    border-radius: 50%;
    rotate: 0deg;
    scale:1;
    transition: 0.5s ease-in;
}

.scroll-top:hover {
    rotate: -90deg;
    border:2px solid #fff;
}

.scroll-top img{
    position: relative;
    top:25%;
    left:50%;
    transform: translate(-50%,-50%);
    width:70%;
    scale:1;
}
.copyright {
    text-align: left;
    padding:50px 50px 20px 50px ;
}

.copyright p{
    color:#fff;
    font-family: 'Syne',sans-serif!important;
    font-size: 20px;
    font-weight: 400;
    opacity: 0.6;
}

.footer-info a{
    color:#fff;
    text-decoration:none;
}

.footer-info a:hover{
    color:#FFD600;
    text-decoration:none;
}

.footer-icon{
    display: none;
}
/* /responsive/ */
@media only screen and (min-width:481px) and (max-width: 769px){
    .col50{
        width:100%!important;
    }
    .above-footer{
        display: block;
    }
    .above-footer .join-info{
        padding:100px!important;
    }
    .above-footer .contact-info{
        padding:100px!important;
    }
}


@media only screen and (max-width: 480px){

    footer{
        padding-top:50px;
        padding-left:15px;
        padding-right:15px;
    }

    .above-footer{
        display: block;
    }
    
    .above-footer .col50{
        width:100%;
    }

    .above-footer .join-info{
        padding:25px;
    }

    .above-footer .join-info h2{
        font-size:40px
    }
    .above-footer .contact-info{
        padding:25px;
    }
    .above-footer .contact-info h2{
        font-size:40px
    }

    .footer-logo { 
        align-items: center;
        padding: 35px 10px;
        scale:1.3;
    }

    #footer-company-info{
        padding:12px;
    }

    footer ul{
        padding:0;
    }
    .footer-info{
        font-size:18px;
    }
    .scroll-top{
        display: none;
    }

    footer .footer-info ul li{
        font-size:21px;
    }

    .footer-icon{
        display: block;
    }
    footer .social-icon{
        display: block;
        position: relative;
        left:0%;
    }
    footer .social-icon ul{
        padding: 10px 11vw;
    }
    .copyright{
        padding:20px 0;
    }


}