.our-services{
    background-color: #00102F;
    padding:50px 0;
    color:#fff;
}

.our-services .title h3{
    font-size: 33px;
}
.our-services .title h3 span{
    color:#ffff00;
}
.our-services .title h4 {
    font-size: 25px;
}
.our-services .accordion-section{
    padding:80px 0;
    background-color: #00102F;
}

.our-services .accordion-section .acc-btn{
    font-size: 26px;
}

.our-services .rotate{
    transform: rotate(45deg)!important;
}
.our-services .acc-btn{
    display: flex!important;
    gap:50px;
    align-items: center;
}
