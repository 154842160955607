.contact-form{
    padding:50px 0;
}

.contact-form form label{
    width:100%;
    padding:30px;
    border:none;
    border-top:1px solid rgba(0, 0, 0, 0.284);
}

.contact-form input{
    width:100%;
    height:50px;
    padding:20px 0; 
    border:none;
    font-size:35px;
    opacity: 0.5;
    font-family: 'syne';
    font-weight:bold;
}
.contact-form:last-child{
    border-bottom:1px solid rgba(0, 0, 0, 0.284);;
}

.contact-form input::placeholder{
    font-size:35px;
    opacity: 0.5;
    font-weight:bold;
    font-family: 'syne';
}

.contact-form input:focus{
    outline:none
}

.contact-form input[type="submit"]{
    position: absolute;
    margin-top: 0px;
    margin-left:30px;
    width: 200px;
    height: 200px;
    background: #1e44d3;
    opacity: 1;
    border-radius: 50%;
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
}

.contact-info .contact-wrapper{
    padding:10px;  
}

.contact-info .contact-wrapper a {
    color:#000;
    text-decoration: none;
}

.contact-info .contact-wrapper a:hover{
    color:#1E44D3;
}

.contact-info .contact-wrapper h4{
    opacity: 0.6;
}

.contact-info .contact-wrapper h3{
    font-weight: bold;
    line-height:30px;
}

.contact-btn{
    height:100px;
    width:100px;
    font-size:25px;
    font-family:syne;
    border-radius:100%;
    background:#1E44D3;
    text-align:center;
    color:#fff;
    padding:75px 25px;
}
.contact-form h2{
    font-size:35px!important;
}

.contact-info .contact-wrapper h3{
    font-size:25px!important;

}

/* /Mobile Responsive/ */

@media only screen and (max-width: 480px){
    .contact-section{
        padding:20px 
    }
    .contact-form input[type="submit"]{
        position: relative;
        margin-top: 20px;
        margin-left: 0px;
        width: 125px;
        height: 125px;
        background: #1e44d3;
        opacity: 1;
        border-radius: 50%;
        color: #fff;
        text-transform: uppercase;
        font-size: 25px;
    }
    
}